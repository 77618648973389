import phone from "../icons/call.png"
import location from "../icons/location.png"
import mail from "../icons/sms.png"

export const supportInfo = [
    {
        title:"tel:18175874919",
        icon : phone,
        info : "(817) 587-4919"
    },
    {
        title:"location",
        icon : location,
        info : "1452 Hughes Rd suite 200 Grapevine Texas 76051"
    },
    {
        title:"mailto:lumanexllc@gmail.com",
        icon : mail,
        info : "lumanexllc@gmail.com"
    }
]